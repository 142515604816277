import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Button, message, Segmented } from "antd";
import { InputOTP } from "antd-input-otp";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { auth } from "../../../firebase.config";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import Cookies from "js-cookie";
import { successAlert, errorAlert } from "../../../utils/helper";
import {
  getClientDetailsByPhone,
  getClientDetailsByEmail,
  selectClient,
  sendOTP,
  verifyOTP,
} from "../../../redux/feature/client/slice";

function SignIn({ onClose, companyId, companyName, companyLogo, handleSuccess }) {
  const dispatch = useDispatch();
  const [usePhone, setUsePhone] = useState(false); // Track login method (phone or email)
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showOTP, setShowOTP] = useState(false);
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  let recaptchaVerifier;

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePhoneNumberChange = (value) => setPhoneNumber(value);

  const handleEnterClick = () => {
    if (usePhone) {
      if (phoneNumber.replace(/\D/g, "").length >= 10) {
        onSignup();
      } else {
        message.error("Please enter a valid phone number");
      }
    } else {
      // Handle email login (you might want to add a separate verification here)
      if (email) {
        onEmailSignup();
      } else {
        message.error("Please enter a valid email address");
      }
    }
  };

  const toggleLoginMethod = () => {
    setUsePhone(!usePhone);
    setShowOTP(false);
    setPhoneNumber("");
    setEmail("");
  };

  const handleOTPChange = (otp) => {
    setOtp(otp);
  };

  const handleCancelClick = () => {
    setShowOTP(false);
    setPhoneNumber("");
    setEmail("");
    setOtp("");
  };

  const onSignup = async () => {
    setLoading(true);
    const clientData = {
      companyId: companyId,
      contact: phoneNumber,
      isEmail: false
    };
    console.log(" Clientt DaTA ", clientData);
    const response = await dispatch(sendOTP(clientData));
    console.log("Response OTP:", response);
    //Error Handling
    setLoading(false)
    setShowOTP(true);
    message.success("OTP sent successfully!");
    
  };
  
  

  const onOTPVerify = async () => {
    setLoading(true);
    const otpString = otp.join("");
    console.log("OTP IS: ", otpString);

    try {
      // const result = await window.confirmationResult.confirm(otpString);
      // const user = result.user;
      //const token = await user.getIdToken();
      const otpData = {
        contact: phoneNumber ? phoneNumber : email,
        otp: otpString,
        companyId: companyId
      };
      await dispatch(verifyOTP(otpData));

      console.log("OTP Verified:");
      message.success("OTP verified successfully!");
      setLoading(false);

      // const client = await dispatch(getClientDetailsByPhone({
      //   companyId,
      //   phoneNumber,
      // }));
      // Check if contact is email or phone number
      const isEmail = otpData.contact.includes('@');

      // Call the appropriate dispatch based on the contact type
      const client = isEmail
        ? await dispatch(getClientDetailsByEmail({ companyId, email: otpData.contact }))
        : await dispatch(getClientDetailsByPhone({ companyId, phoneNumber: otpData.contact }));

      if (client?.payload?.data?.phoneNumber === phoneNumber || client?.payload?.data?.email === email) {
        const expires = new Date();
        expires.setDate(expires.getDate() + 1);
        // Cookies.set("authToken", token, { expires });
        Cookies.set("phoneNumber", client?.payload?.data?.phoneNumber, { expires });
        Cookies.set("email", email, { expires });
        Cookies.set("client_id", client?.payload?.data?._id, { expires });

        successAlert("User has been logged in.");
        setPhoneNumber("");
        setShowOTP(false);
        handleSuccess();
        onClose();
      } else {
        console.error("Phone number mismatch");
        errorAlert("User Not Found.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      message.error("Invalid OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // const onEmailSignup = async () => {
  //   setLoading(true);

  //   const clientData = {
  //     companyId: companyId,
  //     contact: email,
  //     isEmail: true
  //   };

  //   await dispatch(sendOTP(clientData));
  //   setLoading(false)
  //   setShowOTP(true);
  //   message.success("OTP sent successfully!");
  // };

  const onEmailSignup = async () => {
    setLoading(true);
  
    const clientData = {
      companyId: companyId,
      contact: email,
      isEmail: true
    };
  
    try {
      const response = await dispatch(sendOTP(clientData)).unwrap(); // Extracts response or throws error
      console.log("OTP Response:", response);
  
      message.success("OTP sent successfully!");
      setShowOTP(true);
    } catch (error) {
      console.error("Signup Error:", error);
      message.error(error?.error || "Could not send OTP.");
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div>
      <div id="recaptcha-container"></div>

      {/* Toggle between Phone and Email Login */}
      {/* <div style={{ marginBottom: "10px" }}>
    <Segmented
      options={["Phone Login", "Email Login"]}
      value={usePhone ? "Phone Login" : "Email Login"}
      onChange={(value) => toggleLoginMethod(value === "Phone Login")}
    />
  </div> */}
      <div style={{ display: "flex", justifyContent: "center", }}>
        {/* Inline CSS */}
        <style>
          {`
          :where(.css-dev-only-do-not-override-j9bb5n).ant-segmented .ant-segmented-item-label {
            padding-bottom: 5px;
                padding: 0 85px 10px;
            margin-top: 5px;
          }
          :where(.css-dev-only-do-not-override-j9bb5n).ant-segmented .ant-segmented-item-selected {
             background-color: var(--btn-bg-color) !important;  /* Set dynamic background color */
  color: var(--btn-text-color) !important;  /* Set dynamic text color */
          }
             .ant-modal-title {
  text-align: center; /* Center-align the text */
  font-size: 24px !important;    /* Set font size to match h2 */
  font-weight: bold;  /* Optional: Make it bold like h2 */
  margin: 0;          /* Optional: Remove default margins if any */
}

        `}
        </style>
        {/* <Segmented
        // options={["Phone Login", "Email Login"]}
        options={["Email Login"]}
        // value={usePhone ? "Phone Login" : "Email Login"}
        value={ "Email Login"}
        // onChange={(value) => toggleLoginMethod(value === "Phone Login")}
        onChange={(value) => toggleLoginMethod(value === "Email Login")}
        style={{
          fontSize: "28px", // Increase font size
          height: "45px",   // Increase overall height
          width: "290px",
         marginBottom: "30px"
        }}
        optionStyle={{
          color: "--btn-bg-color", // Active segment text color
        }}
      /> */}
        <div className="flex flex-col justify-center items-center p-3 mt-3">
          <div className="w-24 h-24 rounded-full overflow-hidden bg-gray-200">
            <img
              src={companyLogo || "/Logo-Xtreim.png"}
              alt="Company Logo"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="text-[20px] font-semibold mt-2">
            {companyName || "Xtreim"}
          </div>
        </div>

      </div>
      {!showOTP ? (
        <div className="mb-2">
          {usePhone ? (
            <>
              {/* <label htmlFor="phoneNumber" style={{fontSize:"12pt"}}>Phone Number</label>
          <PhoneInput
            name="phoneNumber"
            id="phoneNumber"
            placeholder="Enter Phone Number"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            country={"us"}
            inputProps={{ required: true }}
            inputStyle={{
              width: "100%",
              height: "43px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
            className="mb-3 mt-1"
          /> */}
            </>
          ) : (
            <>
              <label htmlFor="email" className="text-[20px] font-semibold">Email</label>
              <Input
                type="email"
                name="email"
                id="email"
                placeholder="Enter Email"
                value={email}
                onChange={handleEmailChange}
                required
                // style={{
                //   width: "100%",
                //   height: "60px",
                //   borderRadius: "4px",
                //   fontSize: "14pt",
                //   border: "1px solid #ccc",
                // }}
                className="mb-3 mt-1 w-full h-[50px] border-[#ccc] rounded text-[18px] "
              />
            </>
          )}
          {/* <Button
        type="primary"
        onClick={handleEnterClick}
        style={{ marginRight: "10px", height: "40px", fontSize: "16px", width: "50%",justifyContent: "center" , backgroundColor:  "rgb(5, 140, 189)"}}
        loading={loading}
      >
        Send Code
      </Button> */}
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Button
              type="primary"
              onClick={handleEnterClick}
              style={{
                backgroundColor: 'rgb(5, 140, 189)',
              }}
              className="mr-2 h-[40px] text-[16px] w-[50%] justify-center mt-4 my-2 font-semibold hover:bg-transparent"
              loading={loading}
            >
              Send Code
            </Button>
          </div>

          {/* <Button onClick={handleCancelClick} style={{ height: "50px", fontSize: "16px" }}>
        Cancel
      </Button> */}
        </div>
      ) : (
          <>
            <div className="flex flex-col justify-center items-start px-5">
              <label className="text-[20px] font-semibold" htmlFor="otp">OTP</label>
              <InputOTP
                value={otp}
                onChange={handleOTPChange}
                autoFocus
                isInputNum
                numInputs={6}
                separator={<span>-</span>}
                inputStyle={{
                  marginRight: "5px",
                  marginBottom: "20px",
                  marginTop: "20px",
                }}
              />
            </div>
            <div className="flex flex-row justify-center items-center">
              <Button
                type="primary"
                onClick={onOTPVerify}
                style={{ marginRight: "10px", height: "50px", fontSize: "16px", width: "100px", marginTop: "50px" }}
                loading={loading}
              >
                Enter
              </Button>
              <Button onClick={handleCancelClick} style={{ height: "50px", fontSize: "16px", marginTop: "50px" }}>
                Cancel
              </Button>
            </div>
          </>
        
      )}
    </div>
    // <div>
    //   <div id="recaptcha-container"></div>
    //   <Button type="link" onClick={toggleLoginMethod} style={{ marginBottom: "10px" }}>
    //     {usePhone ? "Login with Email" : "Login with Phone"}
    //   </Button>

    //   {!showOTP ? (
    //     <div className="mb-2 mt-4">
    //       {usePhone ? (
    //         <>
    //           <label htmlFor="phoneNumber">Phone Number</label>
    //           <PhoneInput
    //             name="phoneNumber"
    //             id="phoneNumber"
    //             placeholder="Enter Phone Number"
    //             value={phoneNumber}
    //             onChange={handlePhoneNumberChange}
    //             country={"us"}
    //             inputProps={{ required: true }}
    //             inputStyle={{
    //               width: "100%",
    //               height: "43px",
    //               borderRadius: "4px",
    //               border: "1px solid #ccc",
    //             }}
    //             className="mb-3 mt-1"
    //           />
    //         </>
    //       ) : (
    //         <>
    //           <label htmlFor="email">Email</label>
    //           <Input
    //             type="email"
    //             name="email"
    //             id="email"
    //             placeholder="Enter Email"
    //             value={email}
    //             onChange={handleEmailChange}
    //             required
    //             style={{
    //               width: "100%",
    //               height: "43px",
    //               borderRadius: "4px",
    //               border: "1px solid #ccc",
    //             }}
    //             className="mb-3 mt-1"
    //           />
    //         </>
    //       )}
    //       <Button
    //         type="primary"
    //         onClick={handleEnterClick}
    //         style={{ marginRight: "10px", height: "50px", fontSize: "16px", width: "100px" }}
    //         loading={loading}
    //       >
    //         Enter
    //       </Button>
    //       <Button onClick={handleCancelClick} style={{ height: "50px", fontSize: "16px" }}>
    //         Cancel
    //       </Button>
    //     </div>
    //   ) : (
    //     <div>
    //       <label htmlFor="otp">OTP</label>
    //       <InputOTP
    //         value={otp}
    //         onChange={handleOTPChange}
    //         autoFocus
    //         isInputNum
    //         numInputs={6}
    //         separator={<span>-</span>}
    //         inputStyle={{
    //           marginRight: "5px",
    //           marginBottom: "20px",
    //           marginTop: "20px",
    //         }}
    //       />
    //       <Button
    //         type="primary"
    //         onClick={onOTPVerify}
    //         style={{ marginRight: "10px", height: "50px", fontSize: "16px", width: "100px" }}
    //         loading={loading}
    //       >
    //         Enter
    //       </Button>
    //       <Button onClick={handleCancelClick} style={{ height: "50px", fontSize: "16px" }}>
    //         Cancel
    //       </Button>
    //     </div>
    //   )}
    // </div>
  );
}

export default SignIn;

// import React, { useState } from "react";
// import {useDispatch, useSelector} from "react-redux";
// import { Input, Button, message } from "antd";
// import { InputOTP } from "antd-input-otp";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
// import { auth } from "../../../firebase.config";
// import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
// import Cookies from "js-cookie";
// import { successAlert, errorAlert } from "../../../utils/helper";
// import {
//   getClientDetailsByPhone,
//   selectClient,
// } from "../../../redux/feature/client/slice";

// function SignIn({onClose, companyId}) {

//   const dispatch = useDispatch();
//   const [usePhone, setUsePhone] = useState(true); // Track login method (phone or email)
//   const [phoneNumber, setPhoneNumber] = useState("");
//   const [showOTP, setShowOTP] = useState(false);
//   const [otp, setOtp] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [email, setEmail] = useState("");
//   let recaptchaVerifier;
//   const handleEmailChange = (e) => setEmail(e.target.value);
//   const handlePhoneNumberChange = (value) => {
//     setPhoneNumber(value);
//   };

//   // const handleEnterClick = () => {
//   //   if (phoneNumber.replace(/\D/g, '').length >= 10) {
//   //     onSignup();
//   //   } else {
//   //     message.error("Please enter a valid phone number");
//   //   }
//   // };
//   const handleEnterClick = () => {
//     if (usePhone) {
//       if (phoneNumber.replace(/\D/g, "").length >= 10) {
//         onSignup();
//       } else {
//         message.error("Please enter a valid phone number");
//       }
//     } else {
//       // Handle email login (you might want to add a separate verification here)
//       // Implement email verification logic here, if needed
//       message.success("Email login not implemented.");
//     }
//   };
//   const toggleLoginMethod = () => {
//     setUsePhone(!usePhone);
//     setShowOTP(false);
//     setPhoneNumber("");
//     setEmail("");
//   };

//   const handleOTPChange = (otp) => {
//     setOtp(otp);
//   };

//   const handleCancelClick = () => {
//     setShowOTP(false);
//     setPhoneNumber("");
//     setEmail("");
//     setOtp("");
//   };

//   const onSignup = () => {
//     setLoading(true);

//     if (!recaptchaVerifier) {
//       recaptchaVerifier = new RecaptchaVerifier(auth,"recaptcha-container", {
//         size: "invisible",
//         callback: () => {
//         },
//         "expired-callback": () => {
//         },
//       });

//       window.recaptchaVerifier = recaptchaVerifier;
//     }

//     const formatPh = `+${phoneNumber}`;

//     signInWithPhoneNumber(auth, formatPh, recaptchaVerifier)
//       .then((confirmationResult) => {
//         window.confirmationResult = confirmationResult;
//         setLoading(false);
//         setShowOTP(true);
//         message.success("OTP sent successfully!");
//       })
//       .catch((error) => {
//         console.error("Error sending OTP:", error);
//         setLoading(false);
//         message.error("Failed to send OTP. Please try again.");
//       });
//   };

//   const onOTPVerify = async () => {
//     setLoading(true);
//     const otpString = otp.join("");
//     console.log("OTP IS: ", otpString);

//     try {
//         const result = await window.confirmationResult.confirm(otpString);
//         const user = result.user;
//         const token = await user.getIdToken();

//         console.log("OTP Verified:", result);
//         message.success("OTP verified successfully!");
//         setLoading(false);

//         const client = await dispatch(getClientDetailsByPhone({ companyId, phoneNumber }));
//         console.log("Client", client?.payload?.data);

//         if (client?.payload?.data?.phoneNumber === phoneNumber) {
//             console.log("Success");
//             const expires = new Date();
//             expires.setDate(expires.getDate() + 1);
//             Cookies.set("authToken", token, { expires });
//             Cookies.set("phoneNumber", phoneNumber, { expires });

//             successAlert("User Has Been Logged In.");
//             setPhoneNumber("");
//             setShowOTP(false);
//             onClose();
//         } else {
//             console.error("Phone number mismatch");
//             errorAlert("User Not Found.");
//             // message.error("Phone number does not match. Please try again.");
//         }
//     } catch (error) {
//         console.error("Error verifying OTP:", error);
//         message.error("Invalid OTP. Please try again.");
//     } finally {
//         setLoading(false);
//     }
// };


//   return (
//     <div>
//     <div id="recaptcha-container"></div>
//     <Button type="link" onClick={toggleLoginMethod} style={{ marginBottom: "10px" }}>
//       {usePhone ? "Login with Email" : "Login with Phone"}
//     </Button>

//     {!showOTP ? (
//       <div className="mb-2 mt-4">
//         {usePhone ? (
//           <>
//             <label htmlFor="phoneNumber">Phone Number</label>
//             <PhoneInput
//               name="phoneNumber"
//               id="phoneNumber"
//               placeholder="Enter Phone Number"
//               value={phoneNumber}
//               onChange={handlePhoneNumberChange}
//               country={"us"}
//               inputProps={{ required: true }}
//               inputStyle={{
//                 width: "100%",
//                 height: "43px",
//                 borderRadius: "4px",
//                 border: "1px solid #ccc",
//               }}
//               className="mb-3 mt-1"
//             />
//           </>
//         ) : (
//           <>
//             <label htmlFor="email">Email</label>
//             <Input
//               type="email"
//               name="email"
//               id="email"
//               placeholder="Enter Email"
//               value={email}
//               onChange={handleEmailChange}
//               required
//               style={{
//                 width: "100%",
//                 height: "43px",
//                 borderRadius: "4px",
//                 border: "1px solid #ccc",
//               }}
//               className="mb-3 mt-1"
//             />
//           </>
//         )}
//         <Button
//           type="primary"
//           onClick={handleEnterClick}
//           style={{ marginRight: "10px", height: "50px", fontSize: "16px", width: "100px" }}
//           loading={loading}
//         >
//           Enter
//         </Button>
//         <Button onClick={handleCancelClick} style={{ height: "50px", fontSize: "16px" }}>
//           Cancel
//         </Button>
//       </div>
//     ) : (
//       <div>
//         <label htmlFor="otp">OTP</label>
//         <InputOTP
//           value={otp}
//           onChange={handleOTPChange}
//           autoFocus
//           isInputNum
//           numInputs={6}
//           separator={<span>-</span>}
//           inputStyle={{
//             marginRight: "5px",
//             marginBottom: "20px",
//             marginTop: "20px",
//           }}
//         />
//         <Button
//           type="primary"
//           onClick={onOTPVerify}
//           style={{ marginRight: "10px", height: "50px", fontSize: "16px", width: "100px" }}
//           loading={loading}
//         >
//           Enter
//         </Button>
//         <Button onClick={handleCancelClick} style={{ height: "50px", fontSize: "16px" }}>
//           Cancel
//         </Button>
//       </div>
//     )}
//   </div>
//   );
// }

// export default SignIn;