// import React, { useEffect, useState } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import Bynamecard from "./bynameCard";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import "./choosevehicle.css";
// import { getVehicles, selectVehicles, getVehiclesHourlyRate } from "../../redux/feature/vehicle/slice";
// import Loader from "../newLoader/loader";

// function Byname({ goNext, formData, companyId }) {

//     const dispatch = useDispatch();
//     const [loading, setLoading] = useState(true);
//     const vehicles = useSelector(selectVehicles);

//     console.log("Vehicles Here:", vehicles);

//     useEffect(() => {

//         const mainZipCodes = formData?.pickupAddress?.zipCode;
//         const baseRateZipCodes = formData?.dropoffAddress?.zipCode;

//         const zipCodes = {
//             mainZipCode: mainZipCodes,
//             baseRateZipCode: baseRateZipCodes
//         };

//         if (formData?.tripType == "Hourly") {
//             const hours = formData?.duration;
//             console.log("Hoursssss", formData);
//             dispatch(getVehiclesHourlyRate({ companyId, hours }));
//         }
//         else {
//             dispatch(getVehicles({ companyId, zipCodes }));
//         }
//         setLoading(false);
//     }, [dispatch, formData]);

//     const onNextClick = (vehicle) => {
//         goNext(vehicle);
//     };

//     return (
//         <>
//             {loading && (
//                 <div className="fixed h-[80%] flex items-center justify-center w-[80%] z-50">
//                     <Loader size={150} color="#058CBD" />
//                 </div>
//             )}
//             <div className="row d-flex flex-wrap w-100">
//                 {vehicles?.vehicles
//                     ?.filter((vehicle) => vehicle.vehicleStatus !== "inactive" && vehicle.category === "Fleet") // Filter out inactive vehicles
//                     .map((vehicle, index) => (
//                         <div className="col-12 mx-auto my-4" key={index}>
//                             <Bynamecard vehicle={vehicle} goNext={onNextClick} />
//                         </div>
//                     ))}
//             </div>
//         </>
//     )
// }

// export default Byname;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Bynamecard from "./bynameCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./choosevehicle.css";
import {
  getVehicles,
  selectVehicles,
  getVehiclesHourlyRate,
} from "../../redux/feature/vehicle/slice";
import Loader from "../newLoader/loader";
import { selectSingleReservation } from "../../redux/feature/reservation/slice";

function Byname({ goNext, formData, companyId }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const vehicles = useSelector(selectVehicles);
  const reservation = useSelector(selectSingleReservation);

  console.log("Vehicles Here:", vehicles);

  useEffect(() => {
    const fetchVehicles = async () => {
      const mainZipCodes = reservation?.pickupAddress?.zipCode;
      const baseRateZipCodes = reservation?.dropoffAddress?.zipCode;

      const zipCodes = {
        mainZipCode: mainZipCodes,
        baseRateZipCode: baseRateZipCodes,
      };

      if (reservation?.tripType === "Hourly") {
        const hours = reservation?.totalTripDuration;
        console.log("Hoursssss", reservation);
        await dispatch(getVehiclesHourlyRate({ companyId, hours }));
      } else {
        await dispatch(getVehicles({companyId, zipCodes}));
      }

      // Set loading to false after data is fetched
      setLoading(false);
    };

    fetchVehicles();
  }, [dispatch, reservation, companyId]);

  const onNextClick = (vehicle) => {
    goNext(vehicle);
  };

  return (
    <>
      {loading ? (
        <div className="fixed w-[80%] sm:h-[50%] sm:w-[65%] flex items-center justify-center z-50">
          <Loader size={150} />
        </div>
      ) : (
        <div className="row d-flex flex-wrap w-100">
          {vehicles?.vehicles
            ?.filter(
              (vehicle) =>
                vehicle.vehicleStatus !== "inactive" &&
                vehicle.category === "Fleet"
            ) // Filter out inactive vehicles
            .map((vehicle, index) => (
              <div className="col-12 mx-auto my-4" key={index}>
                <Bynamecard vehicle={vehicle} hours={reservation?.totalTripDuration} goNext={onNextClick} />
              </div>
            ))}
        </div>
      )}
    </>
  );
}

export default Byname;
