import axios from "axios";
import { getCompanyFromURL } from "./helper";

export const API = axios.create({
  //  baseURL: "http://localhost:8081/",
  baseURL: "https://limo-server-404607.uc.r.appspot.com/",
});

function queryString({
  page,
  perPage,
  sortField,
  sortDirection,
  search,
  lastDate,
  startDate,
  status,
  download,
  driverId,
  clientId,
  vehicleId,
  companyId,
  requestStatus,
  dispatchStatus,
  isQuote,
}) {
  var company = getCompanyFromURL();
  return `?page=${page}&perPage=${perPage}&company=${company}&sortField=${sortField}&sortDirection=${sortDirection}&search=${search}&lastDate=${lastDate}
  &startDate=${startDate}&status=${status}&download=${download}&driverId=${driverId}&clientId=${clientId}&vehicleId=${vehicleId}&companyId=${companyId}&requestStatus=${requestStatus}&dispatchStatus=${dispatchStatus}&isQuote=${isQuote}`;
}
// export const getVehiclesByZipCode = (companyId, mainZipCode, baseRateZipCode) => {
//   return API.get(
//     `/vehicle-pricing/get-basepricebyzipcode/${companyId}/${mainZipCode}/${baseRateZipCode}`
//   );
// };
export const getVehiclesByZipCode = (companyId, zipCodes) => {
  return API.post(
    `/vehicle-pricing/get-basepricebyzipcode/${companyId}`, zipCodes);
};
export const getVehiclesByHourlyRate = (companyId, hour) => {
  return API.post(
    `/vehicle-pricing/get-hourlyRates/${companyId}`, {
      hours: hour,
  });
};
export const addNewReserwationAPI = (reservation, companyId, decision) => {
  return API.post(`/reservation/book/${companyId}/${decision}`, reservation);
};

export const applyCouponAPI = (couponData) => {
  return API.post(`/coupon/apply-coupon`, couponData);
};

export const addCardAPI = (cardData) => {
  return API.post(`/card/add-booking-card`, cardData);
};

export const addClientAPI = (clientData, companyId) => {
  return API.post(`/client/add-client-booking/${companyId}`, clientData);
};

export const getCompanyAPI = (companyId) => {
  return API.get(`/corporate-company/get-company-details-booking/${companyId}`);
}

export const getClientDetailsByPhoneAPI = (companyId, phoneNumber) => {
  return API.get(`/client/get-client-booking/${companyId}/${phoneNumber}`);
}
export const getClientDetailsByEmailAPI = (companyId, email) => {
  return API.get(`/client/get-client-bookingByEmail/${companyId}/${email}`);
}

export const getCompanyTermsAPI = (id) => {
  return API.get(`/terms/get-terms/${id}`);
}

export const updateCouponCountAPI = (couponData) => {
  return API.post(`/coupon/update-coupon-count`, couponData);
};

export const sendOTPAPI = (clientData) => {
  return API.post(`/message/send-otp`, clientData);
};
export const verifyOTPAPI = (otpData) => {
  return API.post(`/client/verify-otp`, otpData);
};



export const getClientInfoNoAuthAPI = (clientId) =>
  API.get(`/client/get-client-info/${clientId}`);
export const deleteCardAPI = (pmid, cusid, clientId) => {
  return API.post(`/card/delete-cards-client/${pmid}/${cusid}/${clientId}`);
};
export const updateCardAPI = (pmid, cusid, clientId) => {
  return API.post(`/card/update-cards-client/${pmid}/${cusid}/${clientId}`);
};
export const addMultipleCardsAPI = (pmid, cusid, cardtype, clientId) => {
  return API.post(`card/add-multiple-cards-client/${pmid}/${cusid}/${cardtype}/${clientId}`);
};
export const updateClientAPI = (clientId, formData) =>
  API.patch(`/client/update-client-address/${clientId}`, formData);

export const getAlClientlReservationsAPI = (params) => {
  return API.get(`/reservation/get-client-reservations${queryString(params)}`);
};
export const getReservationAPI = (Id, isTrip) => {
  if (isTrip) {
    console.log("IF", isTrip)
    return API.get(`/trip/trip-customer/${Id}`);
  } else {
    console.log("else", isTrip)
    console.log("resId", Id)
    return API.get(`/reservation/reservation-client/${Id}`);
  }
};
export const DownloadReservationAPI = (resId, isTrip) => {
  if (isTrip) {
    return API.get(`/trip/trip-customer-download/${resId}`);
  } else {
    return API.get(`/reservation/reservation/${resId}`);
  }
};

export const getAllOrderTypesAPI = (companyId) => {
  return API.get(`/orderType/getAll-orderTypes/${companyId}`);
};

export const attachCardWithCustomerAPI = (companyId, clientId, cardToken) => {
  return API.post(`/card/attach-clover-card/${companyId}/${clientId}/${cardToken}`);
};

export const detachCardWithCustomerAPI = (companyId, clientId, customerId, cardId) => {
  return API.post(`/card/detach-bt-clover-card/${companyId}/${clientId}/${customerId}/${cardId}`);
};