import React, { useState, useEffect } from "react";
import "./choosevehicle.css";
import Cars from "../img/cars.png";
import Porche from "../img/porche.jpg";
import Rating from "../img/rating.svg";
import Doors from "../img/doors.png";
import MPG from "../img/mpg.png";
import Seats from "../img/seats.png";
import { Icon } from "@iconify/react";
import { useDispatch } from "react-redux";
import {
  updateReservation,
  updateReservation2,
} from "../../redux/feature/reservation/slice";
import { Carousel, Tooltip } from "antd"; // Ant Design Carousel
function Bynamecard({ goNext, vehicle, hours }) {
  const [showFeatures, setShowFeatures] = useState(false);

  const toggleFeaturesVisibility = () => {
    setShowFeatures(!showFeatures);
  };
  const dispatch = useDispatch();

  const handleChange = (field, value) => {
    dispatch(updateReservation({ field, value }));
  };

  const handleChange2 = (field, value) => {
    dispatch(updateReservation2({ field, value }));
  };

  const onNextClick = () => {
    if (vehicle?.basePrice === 0) {
      handleChange("isQuote", true);
      handleChange2("isQuote", true);
    }
    goNext(vehicle);
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);

  // Update the screen size state when the window is resized
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
    };
    window.addEventListener('resize', handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const generalFeatures = vehicle.features.general;
  const availableGeneralFeatures = Object.entries(generalFeatures)
    .filter(([_, feature]) => feature.isAvailable)
    .map(([_, feature]) => ({
      name: feature.name,
      icon: feature.icon,
    }));

  const multimediaFeatures = vehicle.features.multimedia;
  const availableMultimediaFeatures = Object.entries(multimediaFeatures)
    .filter(([_, feature]) => feature.isAvailable)
    .map(([_, feature]) => ({
      name: feature.name,
      icon: feature.icon,
    }));

  const policies = vehicle.features.policies;
  const availablePoliciesFeatures = Object.entries(policies)
    .filter(([_, policy]) => policy.isAvailable)
    .map(([_, policy]) => ({
      name: policy.name,
      icon: policy.icon,
    }));

  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  console.log("Hours Here:", hours);

  return (
    <>
      {/* Content for mobile screens */}
      {isMobile && (
        <div className="container-square-2 d-flex flex-wrap justify-content-center col-lg-11 mx-auto">
          <div className="combined-container d-flex flex-wrap w-100">
            <div className="col-xl-8 col-lg-7 col-md-12 d-flex flex-wrap">
              {/* Left Section */}
              <div className="col-md-6 col-12">
                <Carousel
                  autoplay
                  arrows={true}
                  // infinite={false}
                  dotPosition="bottom"
                  className="unique-carousel"
                >
                  {vehicle.picture && vehicle.picture.length > 0 ? (
                    vehicle.picture.map((image, index) => (
                      <div key={index}>
                        <img
                          style={{ borderRadius: "6px" }}
                          src={image}
                          alt={`Vehicle ${index}`}
                          className="img-fluid img-responsive"
                        />
                      </div>
                    ))
                  ) : (
                    <div>
                      <img
                        style={{ borderRadius: "6px" }}
                        src="https://i.imgur.com/Witktvj.png"
                        alt="Fallback Vehicle"
                        className="img-fluid img-responsive"
                      />
                    </div>
                  )}
                </Carousel>
              </div>

              <div className="col-md-6 col-12 p-3 d-flex flex-column justify-content-between">
                <div>
                  <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
                    <h2 className="heading-1 mt-2">{vehicle.name}</h2>
                  </div>
                  <p className="description-1">
                    {vehicle?.description
                      ? vehicle.description
                      : "No description found"}
                  </p>
                </div>

                {/* <div className="mt-auto d-flex justify-content-between align-items-center w-100">
              <div>
                <button
                  type="button"
                  onClick={onNextClick}
                  className="choose-vehicle btn btn-primary"
                >
                  Select Vehicle
                </button>
              </div>
              <div className="d-flex justify-content-end">
                <div className="old-price" style={{ cursor: "pointer" }}>
                  {vehicle.basePrice === 0
                    ? "Get a Quote"
                    : `$${vehicle.basePrice}`}
                </div>
              </div>
            </div> */}
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{
                    width: "100%", // Full-width container
                  }}
                >
                  {/* Left-Aligned Button */}
                  <button
                    type="button"
                    onClick={onNextClick}
                    className="choose-vehicle btn btn-primary"
                    style={{
                      backgroundColor: "#C4D400",
                      color: "#000",
                      padding: "6px 10px", // Reduced padding for a narrower button
                      marginLeft: "0", // Ensure no margin on the left
                      width: "auto", // Button adjusts to the content size
                      fontSize: '14px'
                    }}
                  >
                    Select Vehicle
                  </button>

                  {/* Right-Aligned Text */}
                  <span
                    className="old-price"
                    style={{
                      cursor: "pointer",
                      fontWeight: "bold",
                      color: "#000040",
                      textAlign: "right",
                      marginRight: "0",
                    }}
                  >
                    {vehicle.basePrice === 0 ? "Get a Quote" : `$${vehicle.basePrice}`}
                  </span>
                </div>
                {vehicle?.minHoursOfBooking != null && vehicle.minHoursOfBooking !== undefined && vehicle.minHoursOfBooking > 0 && hours != 0 && (
                  <>
                    {hours && hours < vehicle.minHoursOfBooking && vehicle.basePrice > 0 && (
                      <div className="flex mt-2 text-[14px] justify-end items-center">
                        <span>
                          Min Hours Are {vehicle.minHoursOfBooking}
                        </span>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="flex flex-col w-100 items-center">
              <div className="relative d-flex items-center mt-2">
                <div style={{ flex: 1, borderTop: '2px solid rgba(0, 0, 0, 0.5)', marginTop: '10px' }}></div>
                <span onClick={toggleFeaturesVisibility} className="mx-4 text-[#929292] font-medium">
                  {showFeatures ? "- Show Less" : "+ Show More"}
                </span>
                <div style={{ flex: 1, borderTop: '2px solid rgba(0, 0, 0, 0.5)', marginTop: '10px' }}></div>
              </div>


              {/* Features Section */}
              <div className="col-xl-4 col-lg-5 col-md-12 mt-4 mt-lg-0 p-4">
                <div className="mb-3">

                  {showFeatures && (
                    <ul className="list-unstyled">
                      <p className="head font-weight-bold text-left">General:</p>
                      {Object.entries(vehicle.features.general)
                        .filter(([_, featureDetails]) => featureDetails.isAvailable)
                        .map(([featureName, featureDetails], index) => (
                          <li
                            key={index}
                            className="mb-2 d-flex align-items-center"
                          >
                            <Icon
                              icon={featureDetails.icon}
                              style={{
                                width: "18px",
                                height: "18px",
                                marginRight: "8px",
                              }}
                            />
                            {featureDetails.name}
                          </li>
                        ))}
                    </ul>
                  )}
                </div>

                <div className="mb-3">

                  {showFeatures && (
                    <ul className="list-unstyled">
                      <p className="head font-weight-bold text-left">Multimedia:</p>
                      {Object.entries(vehicle.features.multimedia)
                        .filter(([_, featureDetails]) => featureDetails.isAvailable)
                        .map(([featureName, featureDetails], index) => (
                          <li
                            key={index}
                            className="mb-2 d-flex align-items-center"
                          >
                            <Icon
                              icon={featureDetails.icon}
                              style={{
                                width: "18px",
                                height: "18px",
                                marginRight: "8px",
                              }}
                            />
                            {featureDetails.name}
                          </li>
                        ))}
                    </ul>
                  )}
                </div>

                <div className="mb-3">

                  {showFeatures && (
                    <ul className="list-unstyled">
                      <p className="head font-weight-bold text-start">Policies:</p>
                      {Object.entries(vehicle.features.policies)
                        .filter(([_, featureDetails]) => featureDetails.isAvailable)
                        .map(([featureName, featureDetails], index) => (
                          <li
                            key={index}
                            className="mb-2 d-flex align-items-center"
                          >
                            <Icon
                              icon={featureDetails.icon}
                              style={{
                                width: "18px",
                                height: "18px",
                                marginRight: "8px",
                              }}
                            />
                            {featureDetails.name}
                          </li>
                        ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Content for large screens */}
      {!isMobile && (
        <div className=" d-flex flex-wrap justify-content-center col-lg-11 mx-auto">
          <div className="combined-container d-flex flex-wrap w-100 px-[20px] py-0 only-1024:px-3 only-1024:py-0">
            <div className="col-xl-8 col-lg-7 col-md-12 d-flex flex-wrap">
              <div className="col-md-6 col-12">
                {/* <img style={{borderRadius: '6px'}} src={Porche} alt="Vehicle" className="w-100" /> */}
                {/* <img 
                style={{borderRadius: '6px'}} 
                src={vehicle.picture && vehicle.picture[0] 
                  ? vehicle.picture[0] 
                  : 'https://i.imgur.com/Witktvj.png'
                } 
                alt="Vehicle" 
                className="img-fluid img-responsive" 
              /> */}
                {/* Ant Design Carousel for Images */}
                <Carousel
                  autoplay
                  arrows={true}
                  // infinite={false}
                  dotPosition="bottom"
                  className="unique-carousel"
                >
                  {/* Map through the images or use a fallback */}
                  {vehicle.picture && vehicle.picture.length > 0
                    ? vehicle.picture.map((image, index) => (
                      <div key={index}>
                        <img
                          style={{ borderRadius: "6px" }}
                          src={image}
                          alt={`Vehicle ${index}`}
                          className="object-contain mx-auto h-[300px] only-1024:h-[250px]"
                        />
                      </div>
                    ))
                    : (
                      /* Fallback Image */
                      <div>
                        <img
                          style={{ borderRadius: "6px" }}
                          src="https://i.imgur.com/Witktvj.png"
                          alt="Fallback Vehicle"
                          className="object-contain mx-auto h-[300px] only-1024:h-[250px]"
                        />
                      </div>
                    )}
                </Carousel>
              </div>
              <div className="col-md-6 col-12 p-6 only-1024:px-7 only-1024:pt-9 only-1024:pb-[60px] d-flex flex-column justify-content-between">
                <div>
                  <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
                    <h2 className="heading-1 mt-2 only-1024:text-[20px]">{capitalize(vehicle.name)}</h2>
                  </div>
                  <p className="description-1 only-1024:text-[14px]">
                    {vehicle?.description ? vehicle.description : "No description found"}
                  </p>
                </div>

                {/* Ensure buttons are aligned at the bottom */}
                <div className="mt-auto d-flex justify-content-between align-items-center">
                  <div>
                    <button type="button" onClick={onNextClick} className="choose-vehicle btn btn-primary ls:text-[10px]">
                      Select Vehicle
                    </button>
                  </div>
                  <div className="d-flex justify-content-end">
                    <div className="old-price ls:text-[12px]" style={{ cursor: 'pointer' }}>
                      {vehicle.basePrice === 0 ? 'Get a Quote' : `$${vehicle.basePrice}`}
                    </div>
                  </div>
                </div>
                {vehicle?.minHoursOfBooking != null && vehicle.minHoursOfBooking !== undefined && vehicle.minHoursOfBooking > 0 && hours != 0 && (
                  <>
                    {hours && hours < vehicle.minHoursOfBooking && vehicle.basePrice > 0 && (
                      <div className="flex mt-2 text-[14px] justify-end items-center">
                        <span>
                          Min Hours Are {vehicle.minHoursOfBooking}
                        </span>
                      </div>
                    )}
                  </>
                )}              
              </div>
            </div>
            {/* Vertical Line */}

            <div className="vertical-line mt-auto mb-auto"></div>
            <div className="col-xl-4 col-lg-5 col-md-12 p-6">
              <div className="row-new mb-3">
                <div className="column-new" >
                  <p className="font-bold text-[18px]">General</p>
                </div>
                <div className="column-new" style={{ marginLeft: '25px' }}>
                  <p className="font-bold text-[18px]">Multimedia</p>
                </div>
                <div className="column-new" style={{ marginLeft: '25px' }}>
                  <p className="font-bold text-[18px]">Policies</p>
                </div>
              </div>

              <div className="mb-2" style={{ display: 'flex', justifyContent: 'space-between' }}>

                <div className="flex flex-row justify-center ml-7 gap-24">
                  <div className="col" >
                    <Tooltip
                      title={
                        availableGeneralFeatures.length > 0 ? (
                          <div>
                            {availableGeneralFeatures.map((feature, index) => (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  color: "white",
                                  marginBottom: "5px",
                                }}
                              >
                                <Icon
                                  icon={feature.icon}
                                  style={{ color: "white", marginRight: "8px" }}
                                />
                                <span className="text-white">{feature.name}</span>
                              </div>
                            ))}
                          </div>
                        ) : (
                          "No available features"
                        )
                      }
                    >
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        {availableGeneralFeatures.length > 0 ? (
                          <Icon
                            icon="fluent:cursor-hover-28-regular"
                            style={{ fontSize: "24px" }}
                          />
                        ) : (
                          <Icon icon="ph:empty" style={{ fontSize: "24px" }} />
                        )}
                      </span>
                    </Tooltip>
                  </div>
                  <div className="col">
                    <Tooltip
                      title={
                        availableMultimediaFeatures.length > 0 ? (
                          <div>
                            {availableMultimediaFeatures.map((feature, index) => (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "5px",
                                }}
                              >
                                <Icon
                                  icon={feature.icon}
                                  style={{ color: "white", marginRight: "8px" }}
                                />
                                <span className="text-white">{feature.name}</span>
                              </div>
                            ))}
                          </div>
                        ) : (
                          "No available features"
                        )
                      }
                    >
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        {availableMultimediaFeatures.length > 0 ? (
                          <Icon
                            icon="fluent:cursor-hover-28-regular"
                            style={{ fontSize: "24px" }}
                          />
                        ) : (
                          <Icon icon="ph:empty" style={{ fontSize: "24px" }} />
                        )}
                      </span>
                    </Tooltip>
                  </div>
                  <div className="col">
                    <Tooltip
                      title={
                        availablePoliciesFeatures.length > 0 ? (
                          <div>
                            {availablePoliciesFeatures.map((policy, index) => (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "5px",
                                }}
                              >
                                <Icon
                                  icon={policy.icon}
                                  style={{ color: "white", marginRight: "8px" }}
                                />
                                <span className="text-white">{policy.name}</span>
                              </div>
                            ))}
                          </div>
                        ) : (
                          "No available policies"
                        )
                      }
                    >
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        {availablePoliciesFeatures.length > 0 ? (
                          <Icon
                            icon="fluent:cursor-hover-28-regular"
                            style={{ fontSize: "24px" }}
                          />
                        ) : (
                          <Icon icon="ph:empty" style={{ fontSize: "24px" }} />
                        )}
                      </span>
                    </Tooltip>
                  </div>
                </div>
                {/* <div className="col" >
                  <Tooltip
                    title={
                      availableGeneralFeatures.length > 0 ? (
                        <div>
                          {availableGeneralFeatures.map((feature, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                color: "white",
                                marginBottom: "5px",
                              }}
                            >
                              <Icon
                                icon={feature.icon}
                                style={{ color: "white", marginRight: "8px" }}
                              />
                              <span className="text-white">{feature.name}</span>
                            </div>
                          ))}
                        </div>
                      ) : (
                        "No available features"
                      )
                    }
                  >
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      {availableGeneralFeatures.length > 0 ? (
                        <Icon
                          icon="fluent:cursor-hover-28-regular"
                          style={{ fontSize: "24px" }}
                        />
                      ) : (
                        <Icon icon="ph:empty" style={{ fontSize: "24px" }} />
                      )}
                    </span>
                  </Tooltip>
                </div> */}


                {/* <div className="col" style={{ marginLeft: '25px' }}>
                  <Tooltip
                    title={
                      availableMultimediaFeatures.length > 0 ? (
                        <div>
                          {availableMultimediaFeatures.map((feature, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "5px",
                              }}
                            >
                              <Icon
                                icon={feature.icon}
                                style={{ color: "white", marginRight: "8px" }}
                              />
                              <span className="text-white">{feature.name}</span>
                            </div>
                          ))}
                        </div>
                      ) : (
                        "No available features"
                      )
                    }
                  >
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      {availableMultimediaFeatures.length > 0 ? (
                        <Icon
                          icon="fluent:cursor-hover-28-regular"
                          style={{ fontSize: "24px" }}
                        />
                      ) : (
                        <Icon icon="ph:empty" style={{ fontSize: "24px" }} />
                      )}
                    </span>
                  </Tooltip>
                </div> */}


                {/* <div className="col" style={{ marginLeft: '10px' }}>
                  <Tooltip
                    title={
                      availablePoliciesFeatures.length > 0 ? (
                        <div>
                          {availablePoliciesFeatures.map((policy, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "5px",
                              }}
                            >
                              <Icon
                                icon={policy.icon}
                                style={{ color: "white", marginRight: "8px" }}
                              />
                              <span className="text-white">{policy.name}</span>
                            </div>
                          ))}
                        </div>
                      ) : (
                        "No available policies"
                      )
                    }
                  >
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      {availablePoliciesFeatures.length > 0 ? (
                        <Icon
                          icon="fluent:cursor-hover-28-regular"
                          style={{ fontSize: "24px" }}
                        />
                      ) : (
                        <Icon icon="ph:empty" style={{ fontSize: "24px" }} />
                      )}
                    </span>
                  </Tooltip>
                </div> */}
              </div>
            </div>

            {/* <div className="flex p-6 px-12 flex-column justify-center items-center">
              <div className="m-2">
                <p className="font-bold text-[18px]">General</p>
                <Tooltip
                  title={
                    availableGeneralFeatures.length > 0 ? (
                      <div>
                        {availableGeneralFeatures.map((feature, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              color: "white",
                              marginBottom: "5px",
                            }}
                          >
                            <Icon
                              icon={feature.icon}
                              style={{ color: "white", marginRight: "8px" }}
                            />
                            <span className="text-white">{feature.name}</span>
                          </div>
                        ))}
                      </div>
                    ) : (
                      "No available features"
                    )
                  }
                >
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    {availableGeneralFeatures.length > 0 ? (
                      <Icon
                        icon="fluent:cursor-hover-28-regular"
                        style={{ fontSize: "24px" }}
                      />
                    ) : (
                      <Icon icon="ph:empty" style={{ fontSize: "24px" }} />
                    )}
                  </span>
                </Tooltip>
              </div>
              <div className="m-2">
                <p className="font-bold text-[18px]">Multimedia</p>
                <Tooltip
                  title={
                    availableMultimediaFeatures.length > 0 ? (
                      <div>
                        {availableMultimediaFeatures.map((feature, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "5px",
                            }}
                          >
                            <Icon
                              icon={feature.icon}
                              style={{ color: "white", marginRight: "8px" }}
                            />
                            <span className="text-white">{feature.name}</span>
                          </div>
                        ))}
                      </div>
                    ) : (
                      "No available features"
                    )
                  }
                >
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    {availableMultimediaFeatures.length > 0 ? (
                      <Icon
                        icon="fluent:cursor-hover-28-regular"
                        style={{ fontSize: "24px" }}
                      />
                    ) : (
                      <Icon icon="ph:empty" style={{ fontSize: "24px" }} />
                    )}
                  </span>
                </Tooltip>
              </div>
              <div className="m-2">
                <p className="font-bold text-[18px]">Policies</p>
                <Tooltip
                  title={
                    availablePoliciesFeatures.length > 0 ? (
                      <div>
                        {availablePoliciesFeatures.map((policy, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "5px",
                            }}
                          >
                            <Icon
                              icon={policy.icon}
                              style={{ color: "white", marginRight: "8px" }}
                            />
                            <span className="text-white">{policy.name}</span>
                          </div>
                        ))}
                      </div>
                    ) : (
                      "No available policies"
                    )
                  }
                >
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    {availablePoliciesFeatures.length > 0 ? (
                      <Icon
                        icon="fluent:cursor-hover-28-regular"
                        style={{ fontSize: "24px" }}
                      />
                    ) : (
                      <Icon icon="ph:empty" style={{ fontSize: "24px" }} />
                    )}
                  </span>
                </Tooltip>
              </div>
            </div> */}

          </div>
        </div>
      )}
    </>
  );
}

export default Bynamecard;

