"use client";
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from "react-redux";
import {attachCardWithCustomer} from "../../redux/feature/card/slice";

const PaymentForm = ({ customer, merchant, onClose, setCard, isAdd, fetchData }) => {
    const dispatch = useDispatch();
    const cloverRef = useRef(null);
    const scriptRef = useRef(null);
    const [elements, setElements] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    // console.log("Merchant Details Here:", merchant);

    const cleanup = () => {
        console.log("I am here");
        // Remove Clover script
        if (scriptRef.current) {
            document.body.removeChild(scriptRef.current);
            scriptRef.current = null;
        }

        // Remove any Clover iframes
        const iframes = document.querySelectorAll('iframe[src*="clover"]');
        iframes.forEach(iframe => iframe.remove());

        // Remove any Clover-related elements
        const cloverElements = document.querySelectorAll('[class*="clover"], [id*="clover"]');
        cloverElements.forEach(element => element.remove());

        // Clear the mount points
        ['card-number', 'card-date', 'card-cvv', 'card-postal-code'].forEach(id => {
            const element = document.getElementById(id);
            if (element) {
                element.innerHTML = '';
            }
        });

        // Reset state
        setElements(null);
        cloverRef.current = null;
        console.log("Now, I am here");
    };

    useEffect(() => {
        const loadCloverSDK = () => {
            cleanup(); // Clean up any existing instances first
            
            const script = document.createElement('script');
            scriptRef.current = script;
            script.src = 'https://checkout.sandbox.dev.clover.com/sdk.js';
            script.async = true;
            script.onload = () => {
                console.log("Clover SDK loaded");
                initializeClover();
            };
            script.onerror = () => {
                console.error("Failed to load Clover SDK");
                setError("Failed to load payment system");
            };
            document.body.appendChild(script);
        };

        const initializeClover = () => {
            try {
                cloverRef.current = new window.Clover(merchant?.cloverPAKMS, {
                    merchantId: merchant?.cloverMerchantId,
                    environment: 'sandbox',
                    domain: 'http://localhost:4000'
                });

                const cloverElements = cloverRef.current.elements({
                    style: {
                        base: {
                            fontSize: '16px',
                            color: '#424770',
                            '::placeholder': {
                                color: '#aab7c4',
                            },
                        },
                        invalid: {
                            color: '#9e2146',
                        },
                    }
                });

                mountCloverElements(cloverElements);
                setElements(cloverElements);
            } catch (err) {
                console.error("Clover initialization error:", err);
                setError("Failed to initialize payment system");
            }
        };

        const mountCloverElements = (cloverElements) => {
            try {
                const elementOptions = {
                    style: {
                        base: {
                            fontSize: '16px',
                            color: '#424770',
                            '::placeholder': {
                                color: '#aab7c4',
                            },
                        },
                        invalid: {
                            color: '#9e2146',
                        },
                    }
                };

                cloverElements.create('CARD_NUMBER', elementOptions).mount('#card-number');
                cloverElements.create('CARD_DATE', elementOptions).mount('#card-date');
                cloverElements.create('CARD_CVV', elementOptions).mount('#card-cvv');
                cloverElements.create('CARD_POSTAL_CODE', elementOptions).mount('#card-postal-code');

                console.log("Card elements mounted");
            } catch (err) {
                console.error("Error mounting elements:", err);
                setError("Failed to load payment form");
            }
        };

        loadCloverSDK();

        // Return cleanup function
        return () => {
            cleanup();
        };
    }, [merchant?.cloverMerchantId]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);

        try {
            if (!cloverRef.current || !elements) {
                throw new Error("Payment form not properly initialized");
            }

            // const result = await cloverRef.current.createToken();
            const result = await cloverRef.current.createToken({
                verify: true,
                store: true,
                verifyAmount: 0,
            });
            const clientId = customer?._id;
            const cardToken = result.token;
            const companyId = merchant?._id;
            console.log("Card Token:", result);
            if (result.token) {
                const cardDetails = {
                    first6: result.card.first6,
                    last4: result.card.last4,
                    // id: result.card.first6 + result.card.last4,
                    cardType: result.card.brand,
                    last4: result.card.last4,
                    expirationDate: `${result.card.exp_month}${result.card.exp_year}`,
                    token: result.token,
                };
                if (isAdd)
                {
                    setCard(cardDetails);
                }
                else
                {
                    await dispatch(attachCardWithCustomer({companyId, clientId, cardToken}));
                }
                
                if (onClose) {
                    fetchData();
                    onClose(); // Close the modal on success
                }
            } else {
                throw new Error(result.error || "Failed to add card information");
            }
        } catch (err) {
            console.error("Error during tokenization:", err);
            setError(err.message || "Failed to add card. Please check your card details and try again.");
        } finally {
            setIsLoading(false);
        }
    };

    if (error) {
        return (
            <div className="max-w-lg mx-auto p-6">
                <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-md">
                    {error}
                </div>
            </div>
        );
    }

    return (
        <form onSubmit={handleSubmit} id="payment-form" className="max-w-lg mx-auto p-6 bg-white shadow-md rounded-lg">
            <h2 className="text-2xl font-semibold mb-6 text-center">Save Your Card</h2>

            <div className="space-y-4">
                <div id="card-number" className="h-12 border rounded-md p-3"></div>
                <div className="flex space-x-4">
                    <div id="card-date" className="flex-1 h-12 border rounded-md p-3"></div>
                    <div id="card-cvv" className="flex-1 h-12 border rounded-md p-3"></div>
                </div>
                <div id="card-postal-code" className="h-12 border rounded-md p-3"></div>
            </div>

            <button
                type="submit"
                disabled={isLoading}
                className={`mt-6 w-full bg-blue-600 text-white py-3 rounded-md transition-colors ${
                    isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-700'
                }`}
            >
                {isLoading ? 'Processing...' : 'Save Card'}
            </button>
            
        </form>
    );
};

export default PaymentForm;